import "./App.css";

function App()  {
  return (
<div className="App">
  <div className="header">
    <img src="https://scontent-hkg4-2.xx.fbcdn.net/v/t39.30808-6/441043889_835145031983700_1946370424284281136_n.jpg?stp=cp6_dst-jpg&_nc_cat=111&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=xOvQlv37u9wQ7kNvgE1zNAI&_nc_ht=scontent-hkg4-2.xx&_nc_gid=AbTuTAsChFMMIe2Wfbv-5Mw&oh=00_AYA80gNk7Wg8xGiqVLiTUBplGNR7NItS30I-jIHyDrGZ5g&oe=66F55735" alt="Logo" className="logo" />

  <div className="text">
      <div className="welcome">Welcome</div>
      <div className="brand">MITUPATATTOO</div>
      <div className="tagline">Make an appointment and connect with us!</div>
  </div>
  </div>

  <div className="Map">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.9838278511747!2d105.79964641116452!3d21.033333080536224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab44721aec5f%3A0xfe0e27ad26584edc!2sMitupa%20Tattoo%20%26%20Painting!5e0!3m2!1svi!2s!4v1727098530948!5m2!1svi!2s" 
  width="300" 
  height="300" 
  style={{border: "0" }}
  allowfullscreen="" 
  loading="lazy" 
  referrerpolicy="no-referrer-when-downgrade">

  </iframe>
  </div>

  <div className="main">
      <div className="link-itemm">
         <div className="link-itemm1">
         <img src="https://cdn-icons-png.flaticon.com/128/3059/3059484.png" alt="logo" />
          </div>
          <div className="link-itemm2">
          <a href="https://chillwithme.online">Website Mitupa Tattoo</a>
          </div>
      </div>

      <div className="link-itemm">
          <div className="link-itemm1">
          <img src="https://cdn-icons-png.flaticon.com/128/1384/1384005.png" alt="logo" />
        </div>
        <div className="link-itemm2">
          <a href="https://www.facebook.com/minhthu.phan.77398/"> Facebook cô chủ Minh Thư Phan</a>
        </div>
      </div>

        <div className="link-itemm">
          <div className="link-itemm1">
          <img src="https://cdn-icons-png.flaticon.com/128/1384/1384005.png" alt="logo" />
          </div>
          <div className="link-itemm2">
          <a href="https://www.facebook.com/mitupa.tattoo.painting?mibextid=LQQJ4d"> Facebook Mitupa Tattoo</a>
          </div>
        </div>

        <div className="link-itemm">
        <div className="link-itemm1">
        <img src="https://cdn-icons-png.flaticon.com/128/1384/1384005.png" alt="logo" />
        </div>
        <div className="link-itemm2">
          <a href="https://www.facebook.com/profile.php?id=61553744483256&mibextid=LQQJ4d">Facebook lớp đào tạo nghề xăm Mitupa Tattoo</a>
          </div>
        </div>

        <div className="link-itemm">
           <div className="link-itemm1">
           <img src="https://cdn-icons-png.flaticon.com/128/3669/3669950.png" alt="logo" />
           </div>
           <div className="link-itemm2">
          <a href="https://www.tiktok.com/@minhthuphan1208?_t=8pxlTkDAbSs&_r=1"> Tiktok cô chủ Minh Thư Phan</a>
          </div>
        </div>

        <div className="link-itemm">
           <div className="link-itemm1">
           <img src="https://cdn-icons-png.flaticon.com/128/3669/3669950.png" alt="logo" />
           </div>
           <div className="link-itemm2">
          <a href="https://www.tiktok.com/@mitupatattoo?_t=8pxlR4SH1bW&_r=1">Tiktok Mitupa Tattoo</a>
          </div>
        </div>

        <div className="link-itemm">
            <div className="link-itemm1">
            <img src="https://cdn-icons-png.flaticon.com/128/1384/1384015.png" alt="logo" />
            </div>
            <div className="link-itemm2">
            <a href="https://www.instagram.com/mitupatattoo?igsh=ZThnanV2djl6aXgz">Instagram của cô chủ Mitupa Tattoo</a>
            </div>
        </div>

        <div className="link-itemm">
            <div className="link-itemm1">
            <img src="https://cdn-icons-png.flaticon.com/128/1384/1384015.png" alt="logo" />
            </div>
            <div className="link-itemm2">
            <a href="https://www.instagram.com/mitupatattoo?igsh=ZThnanV2djl6aXgz">Instagram của Mitupa Tattoo</a>
            </div>
        </div>

      <div className="link-itemm">
        <div className="link-itemm1">
        <img src="https://cdn-icons-png.flaticon.com/128/1384/1384015.png" alt="logo" />
          </div>
          <div className="link-itemm2">
          <a href="https://www.instagram.com/mitupa_dao_tao_nghe_xam?igsh=eGp2dTYwODliYnVo">Instagram lớp đào tạo nghề xăm Mitupa Tattoo</a>
          </div>
        </div>

  </div>

</div>


  )}
export default App;
